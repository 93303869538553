<template>
  <div class="news-template">
    <div class="header"></div>
    <div class="title">
      <h3>招纳贤士</h3>
    </div>
    <el-divider></el-divider>
    <div class="container">
      <div class="row">
        <div class="card card-shadow">
          <h4 class="card-title p-0 font-size-24">产品销售经理</h4>
          <p class="card-metas font-size-12 blue-grey-400">
            <span class="m-r-10">2019-09-15</span>
            <span class="m-r-10">
              <i class="icon wb-map m-r-5" aria-hidden="true"></i>
              全国
            </span>
            <span class="m-r-10">
              <i class="icon wb-user m-r-5" aria-hidden="true"></i>
              3
            </span>
            <span>
              <i class="icon wb-payment m-r-5" aria-hidden="true"></i>
              10K+
            </span>
          </p>
          <hr />
          <div class="met-editor clearfix">
            <ul class="list-paddingleft-2" style="list-style-type: disc">
              <li>
                <p>
                  <span style="font-size: 16px"
                    ><strong
                      ><span style="font-family: 微软雅黑, sans-serif"
                        >基础岗位要求：</span
                      ></strong
                    ></span
                  >
                </p>
              </li>
            </ul>
            <p style="margin-left: 0; text-indent: 0">
              <span
                style="font-size: 16px; font-family: 微软雅黑, MicrosoftYaHei"
                >1.大专及以上学历，专业不限，可接受应届毕业生；</span
              >
            </p>
            <p style="margin-left: 0; text-indent: 0">
              <span
                style="font-size: 16px; font-family: 微软雅黑, MicrosoftYaHei"
                >2.具备一定的销售服务器、软硬件销售经验。条件优秀者可适当放宽条件；</span
              >
            </p>
            <p style="margin-left: 0; text-indent: 0">
              <span
                style="font-size: 16px; font-family: 微软雅黑, MicrosoftYaHei"
                >3.负责公司的业务拓展、销售运作，能强有力的将计划转变成结果；</span
              >
            </p>
            <p style="margin-left: 0; text-indent: 0">
              <span
                style="font-size: 16px; font-family: 微软雅黑, MicrosoftYaHei"
                >4.性格外向、反应敏捷、表达能力强，具有较强的沟通能力及交际技巧，具有亲和力；&nbsp;&nbsp;</span
              >
            </p>
            <p style="margin-left: 0; text-indent: 0">
              <span
                style="font-size: 16px; font-family: 微软雅黑, MicrosoftYaHei"
                >5.具备一定的市场分析及判断能力，良好的客户服务意识；
              </span>
            </p>
            <p style="margin-left: 0; text-indent: 0">
              <span
                style="font-size: 16px; font-family: 微软雅黑, MicrosoftYaHei"
                >6.具有强烈的进取心，精力充沛，身体健康，乐观豁达，富有开拓&nbsp;精神，能承受一定的工作压力；</span
              >
            </p>
            <p style="margin-left: 0; text-indent: 0">
              <span
                style="font-size: 16px; font-family: 微软雅黑, MicrosoftYaHei"
                >7.品行端正，无犯罪记录及不良嗜好。</span
              >
            </p>
            <p style="margin-left: 0; text-indent: 0">
              <span
                style="font-size: 16px; font-family: 微软雅黑, MicrosoftYaHei"
                ><br
              /></span>
            </p>
            <ul class="list-paddingleft-2" style="list-style-type: disc">
              <li>
                <p>
                  <span style="font-size: 16px"
                    ><strong
                      ><span style="font-family: 微软雅黑, sans-serif"
                        >岗位竞争力（加分选项）：</span
                      ></strong
                    ></span
                  >
                </p>
              </li>
            </ul>
            <p style="margin-left: 0; text-indent: 0">
              <span style="font-family: 微软雅黑, sans-serif; font-size: 16px"
                >1.计算机、市场营销、经济类相关专业。</span
              >
            </p>
            <p style="margin-left: 0; text-indent: 0">
              <span style="font-family: 微软雅黑, sans-serif; font-size: 16px"
                >2.销售从业经验，公关从业经验等。</span
              >
            </p>
            <p style="margin-left: 0; text-indent: 0">
              <span style="font-family: 微软雅黑, sans-serif; font-size: 16px"
                >3.行业背景（金融行业等）。</span
              >
            </p>
            <p style="margin-left: 0; text-indent: 0">
              <span style="font-family: 微软雅黑, sans-serif; font-size: 16px"
                >4.有丰富人脉资源。</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, sans-serif; font-size: 16px"
                >&nbsp;</span
              >
            </p>
            <ul class="list-paddingleft-2" style="list-style-type: disc">
              <li>
                <p>
                  <span style="font-size: 16px"
                    ><strong
                      ><span style="font-family: 微软雅黑, sans-serif"
                        >福利保障：</span
                      ></strong
                    ><strong
                      ><span
                        style="font-family: 微软雅黑, sans-serif"
                      ></span></strong
                    ><strong
                      ><span
                        style="font-family: 微软雅黑, sans-serif"
                      ></span></strong
                  ></span>
                </p>
              </li>
            </ul>
            <p>
              <span style="font-family: 微软雅黑, sans-serif; font-size: 16px"
                >五天工作制+五险一金+人身意外险+工作餐+年假+生日福利+培训晋升平台</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, sans-serif; font-size: 16px"
                >&nbsp;</span
              >
            </p>
            <ul class="list-paddingleft-2" style="list-style-type: disc">
              <li>
                <p>
                  <span style="font-size: 16px"
                    ><strong
                      ><span style="font-family: 微软雅黑, sans-serif"
                        >薪酬：</span
                      ></strong
                    ><strong
                      ><span
                        style="font-family: 微软雅黑, sans-serif"
                      ></span></strong
                    ><strong
                      ><span
                        style="font-family: 微软雅黑, sans-serif"
                      ></span></strong
                  ></span>
                </p>
              </li>
            </ul>
            <p>
              <span style="font-family: 微软雅黑, sans-serif; font-size: 16px"
                >无责任底薪+高额提成，薪酬待遇优厚，具体面谈。</span
              >
            </p>
            <p><br /></p>
          </div>
          <hr />
          <div class="card-body-footer m-t-0">
          </div>
        </div>
        <div class="card card-shadow">
          <h4 class="card-title p-0 font-size-24">JAVA软件工程师</h4>
          <p class="card-metas font-size-12 blue-grey-400">
            <span class="m-r-10">2019-09-15</span>
            <span class="m-r-10">
              <i class="icon wb-map m-r-5" aria-hidden="true"></i>
              北京
            </span>
            <span class="m-r-10">
              <i class="icon wb-user m-r-5" aria-hidden="true"></i>
              5
            </span>
            <span>
              <i class="icon wb-payment m-r-5" aria-hidden="true"></i>
              15K+
            </span>
          </p>
          <hr />
          <div class="met-editor clearfix">
            <ul class="list-paddingleft-2" style="list-style-type: disc">
              <li>
                <p>
                  <span style="font-family: 微软雅黑, MicrosoftYaHei"
                    ><strong>岗位描述：</strong></span
                  >
                </p>
              </li>
            </ul>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >1、根据项目计划从事详细设计、开发、测试和维护工作；</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >2、负责产品软件设计与开发实现，优化现有系统及功能升级；</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >3、参与系统的需求分析、设计、编码、单元测试、BUG解决等工作；</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >4、配合各业务部门，从技术上给予指导。</span
              >
            </p>
            <p><br /></p>
            <ul class="list-paddingleft-2" style="list-style-type: disc">
              <li>
                <p>
                  <span style="font-family: 微软雅黑, MicrosoftYaHei"
                    ><strong>任职要求：</strong></span
                  >
                </p>
              </li>
            </ul>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >1、计算机相关专业本科及以上学历；</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >2、两年及以上JAVA开发经验，JAVA基础扎实，熟悉IO、多线程、分布式、负载均衡、缓存、消息等技术；</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >3、熟悉HTML、CSS、JavaScript、Ajax、JQuery、ExtJS、JSP等相关技术，并有实际项目开发经验；</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >4、精通Java
                EE相关技术，熟悉Struts2、Spring3、Hibernate3、MyBatis等主流的开发框架；</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >5、熟悉网络常用通讯协议，熟悉ftp、http等传输协议以及socket开发；</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >6、熟悉Oracle、MySql、SQL
                Server等数据库，有数据库优化经验者优先；</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >7、熟练掌握常用的Linux命令 、shell脚本；</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >8、熟悉常用设计模式，有大型分布式、高并发、高负载、高可用性系统设计开发经验者优先；</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >9、具有良好的沟通能力、团队合作精神和职业稳定性，工作积极主动、服从安排，能承受较大压力，严谨的编码风格，责任心和学习能力强，有较强的架构能力及优秀的逻辑思维能力，具有丰富的项目开发经验，能独立分析问题及解决；</span
              >
            </p>
            <p><br /></p>
            <ul class="list-paddingleft-2" style="list-style-type: disc">
              <li>
                <p>
                  <span style="font-family: 微软雅黑, MicrosoftYaHei"
                    ><strong>公司福利：</strong></span
                  >
                </p>
              </li>
            </ul>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >1、社会保险（养老保险、医疗保险、失业保险、工伤保险和生育保险国家规定项目）</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >2、住房公积金</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >3、有薪假期（法定节假日、年休假、婚假、生育假等有薪假期）</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >4、员工生日福利</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >5、项目奖（根据项目收入给予一定的奖励）</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >6、文娱活动（每周羽毛球、篮球、足球等定期活期）</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >7、其他临时发放的福利</span
              >
            </p>
            <p>
              <span style="font-family: 微软雅黑, MicrosoftYaHei"
                >8、五天八小时工作制</span
              >
            </p>
          </div>
          <hr />
          <div class="card-body-footer m-t-0">
          </div>
        </div>
      </div>
    </div>
    <div class="newsfooter"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contentText: `&nbsp;&nbsp;本报济南4月17日电  （记者徐锦庚、李蕊）今年是胜利油田发现60周年。位于黄河入海口的胜利油田迄今累计生产原油12.5亿吨，占全国同期陆上原油产量1/5，目前保持年稳产2340万吨以上。`,
    };
  },
};
</script>
<style lang="scss" scoped>
.container{
    text-align: left;
    font-size: 15px;
    margin: 0 10%;
    line-height: 1.7;
    color: #555555;
    .font-size-24{
        font-size: 24px;
    }
}
.news-template {
  text-align: center;
  width: 100%;
  font-family: Hiragino Sans GB, Microsoft Yahei, \\5fae\8f6f\96c5\9ed1, SimSun,
    \\5b8b\4f53, Arial;
  .header {
    height: 6em;
    background: #04c9a0;
  }
  h3 {
    font-size: 1.7em;
    margin: 0.7em 0;
  }
  h5 {
    margin: 0.5em 0;
    color: #909399;
  }
  .content {
    width: 75%;
    margin: 0 12%;
    p {
      font-size: 1.2em;
      text-align: left;
      line-height: 2.2;
      margin-bottom: 2em;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }
}
</style>